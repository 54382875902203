import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import './Layout.css';

const Layout = ({ data, children }) => (
  <>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[
        { name: 'description', content: 'Nom de domaine à vendre' },
        { name: 'keywords', content: 'stcyp, stcyp.net, stcyp.fr, à vendre, en vente, for sale' },
      ]}
    >
      <html lang="fr" />
    </Helmet>
    <Header siteTitle={data.site.siteMetadata.title} />
    <div
      style={{
        margin: '0 auto',
        maxWidth: 960,
        padding: '0px 1.0875rem 1.45rem',
        paddingTop: 0,
      }}
    >
      {children}
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default props => (
  <StaticQuery
    query={graphql`query SiteTitleQuery { site { siteMetadata { title } } }`}
    render={data => (<Layout {...props} data={data} />)}
  />
);
